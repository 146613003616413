<script setup lang="ts">
import { PageLayout } from '@ijru/components/templates'
import { TextButton } from '@ijru/components/atoms'
import { useIsAuthenticated, useMsal } from './hooks/msal'
import { loginRequest } from './msalConfig'
import { useHead } from '@vueuse/head'
import { useRouter } from 'vue-router'
import SelectMenuRow from './components/SelectMenuRow.vue'
import NotificationCards from './components/NotificationCards.vue'
import RefreshNeeded from './components/RefreshNeeded.vue'

const isAuthenticated = useIsAuthenticated()
const msal = useMsal()

function login () {
  msal.instance.loginRedirect(loginRequest)
}
function logout () {
  msal.instance.logoutRedirect()
}

const router = useRouter()
const menuRoutes = router.getRoutes()
  .filter(r => typeof r.meta.menu === 'string')
  .sort((a, b) => (a.meta.menu as string).localeCompare(b.meta.menu as string))

const version = (import.meta.env.VITE_COMMIT_REF ?? 'ijru-tools-frontend@dev').toString().split('@')[1].substring(0, 7)

useHead({
  titleTemplate: (title) => !title ? 'IJRU Registration Tools' : `${title} | IJRU Registration Tools`
})
</script>

<template>
  <page-layout title="IJRU Registration &ndash; Tools">
    <template #nav>
      <text-button v-if="!isAuthenticated" @click="login">
        Log in
      </text-button>
      <text-button v-else @click="logout">
        Log Out
      </text-button>
    </template>

    <template #sidebar="{ close }">
      <template v-for="route of menuRoutes" :key="route.name">
        <router-link
          v-if="!route.meta.requiresAuth || (route.meta.requiresAuth && isAuthenticated)"
          v-slot="{ navigate, isActive }"
          custom
          :to="route.path"
        >
          <select-menu-row :class="{ 'font-bold': isActive }" @click="navigate(), close()">
            {{ route.meta.menu }}
          </select-menu-row>
        </router-link>
      </template>

      <div class="absolute bottom-0 left-0 right-0 p-2 bg-white border-t">
        version: {{ version }}
      </div>
    </template>

    <router-view />
  </page-layout>

  <notification-cards />
  <refresh-needed />
</template>

<style>
@media print {
  body {
    background-color: white !important;
  }
  main {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }
  .noprint {
    display: none !important;
  }
}
</style>
