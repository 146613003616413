<script setup lang="ts">
import IconChevronRight from '~icons/mdi/chevron-right'
import MenuRow from './MenuRow.vue'

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<(event: 'click') => void>()
</script>

<template>
  <menu-row class="cursor-pointer" @click="emit('click')">
    <slot v-bind="$attrs" />

    <template #append>
      <slot name="icon">
        <icon-chevron-right class="-mb-0.5" />
      </slot>
    </template>
  </menu-row>
</template>
