import { LogLevel, PublicClientApplication } from '@azure/msal-browser'

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_MSAL_CLIENT_ID,
    authority: `https://${import.meta.env.VITE_MSAL_TENANT_SHORTNAME}.b2clogin.com/${import.meta.env.VITE_MSAL_TENANT_SHORTNAME}.onmicrosoft.com/${import.meta.env.VITE_MSAL_B2C_SIGNUPIN_FLOW}`,
    knownAuthorities: [`${import.meta.env.VITE_MSAL_TENANT_SHORTNAME}.b2clogin.com`],
    redirectUri: '/', // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: '/' // Must be registered as a SPA redirectURI on your app registration
  },
  cache: {
    cacheLocation: 'localStorage'
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
        }
      },
      logLevel: LogLevel.Verbose
    }
  }
}

export const msalInstance = new PublicClientApplication(msalConfig)

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
  scopes: ['openid', 'offline_access', import.meta.env.VITE_MSAL_CLIENT_ID]
}
