<template>
  <div v-if="needRefresh && !dismissed" class="fixed bottom-0 right-0 left-0 border-t bg-red-100 py-2 overflow-x-auto z-100000">
    <div class="container mx-auto px-2 flex flex-wrap items-baseline gap-2 justify-between">
      <p>New version available, click on reload to update.</p>

      <div class="flex gap-4 flex-nowrap">
        <text-button @click="updateServiceWorker()">
          Reload
        </text-button>
        <text-button @click="dismissed = true">
          Dismiss
        </text-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { TextButton } from '@ijru/components/atoms'

const dismissed = ref(false)
const { needRefresh, updateServiceWorker } = useRegisterSW()
</script>
