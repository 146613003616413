import { ApolloClient, createHttpLink, InMemoryCache, from, defaultDataIdFromObject } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import useNotifications from './hooks/useNotifications'
import { loginRequest, msalInstance } from './msalConfig'
import { InteractionRequiredAuthError } from '@azure/msal-browser'

const httpLink = createHttpLink({
  uri: `${import.meta.env.VITE_REGISTRATION_URL}/graphql`,
  includeExtensions: true
})

function customDataIdFromObject (result: { __typename?: string, [prop: string]: any }): string | null {
  const tn = result.__typename

  if (!tn?.length) return null

  const tnFirst = tn.substring(0, 1).toLocaleLowerCase()
  const tnRest = tn.substring(1)

  let path = tnFirst + tnRest + 'Id'

  if (tn === 'VolunteerSlot') return null
  if (tn === 'CompetitionEntry') path = 'entryId'
  if (tn === 'GenderDefinition') path = 'genderId'
  if (tn === 'AgegroupDefinition') path = 'agegroupId'

  if (!result[path]) return null

  return `${tn}:${result[path] as string}`
}

const authLink = setContext(async (_, { headers }) => {
  await msalInstance.initialize()
  const account = msalInstance.getActiveAccount()
  let accessToken
  if (account) {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account
    }).catch(async err => {
      if (err instanceof InteractionRequiredAuthError) {
        await msalInstance.acquireTokenRedirect(loginRequest)
      }
      throw err
    })
    accessToken = response.accessToken
  }

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : ''
    }
  }
})

const notifs = useNotifications()
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      console.log({ ...err })
      notifs.push({
        message: err.message,
        type: 'server',
        code: typeof err.extensions.code === 'string' ? err.extensions.code : undefined
      })
    }
  }
  if (networkError) {
    notifs.push({
      message: networkError.message,
      type: 'network'
    })
  }
})

const cache = new InMemoryCache({
  dataIdFromObject: (object): string => {
    return customDataIdFromObject(object) ?? defaultDataIdFromObject(object) ?? ''
  }
})

export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache
})
