<template>
  <div v-bind="$attrs" class="grid grid-cols-[auto_max-content] h-10 border-b items-center">
    <div class="px-2 text-ellipsis max-w-full overflow-hidden whitespace-nowrap">
      <slot />
    </div>

    <div class="mx-2">
      <slot name="append" />
    </div>
  </div>
</template>
