<template>
  <aside class="fixed bottom-2 right-2 w-[50ch] max-w-[50vw] z-2000 flex flex-col gap-2">
    <note-card v-for="notif of notes" :key="notif.id" :color="isErrorMessage(notif) ? (notif.type === 'server' ? 'red' : 'yellow') : notif.color">
      {{ notif.message }}
    </note-card>
  </aside>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import useNotifications, { isErrorMessage } from '../hooks/useNotifications'

import { NoteCard } from '@ijru/components/atoms'

const notifications = useNotifications()

const notes = computed(() => {
  return notifications.notifications.value.slice(0, 5)
})
</script>
