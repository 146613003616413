import { type AccountInfo, type PublicClientApplication, type AuthenticationResult, type AuthError, InteractionStatus, InteractionType, type PopupRequest, type RedirectRequest, type SilentRequest } from '@azure/msal-browser'
import { getCurrentInstance, toRefs, computed, type Ref, ref, watch } from 'vue'
import { app } from '../main'

export interface MsalContext {
  instance: PublicClientApplication
  accounts: Ref<AccountInfo[]>
  inProgress: Ref<InteractionStatus>
}

export function useMsal (): MsalContext {
  const internalInstance = getCurrentInstance() ?? app._instance
  if (!internalInstance) {
    throw new Error('useMsal() cannot be called outside the setup() function of a component')
  }
  const { instance, accounts, inProgress } = toRefs(internalInstance.appContext.config.globalProperties.$msal)

  if (!instance.value || !accounts.value || !inProgress.value) {
    throw new Error('Please install the msalPlugin')
  }

  if (inProgress.value === InteractionStatus.Startup) {
    instance.value.initialize().then(() => {
      instance.value.handleRedirectPromise().catch(() => {
        // Errors should be handled by listening to the LOGIN_FAILURE event
      })
    })
  }

  return {
    instance: instance.value,
    accounts,
    inProgress
  }
}

export function useIsAuthenticated (): Ref<boolean> {
  const { accounts } = useMsal()
  const isAuthenticated = computed(() => accounts.value.length > 0)

  return isAuthenticated
}

export interface MsalAuthenticationResult {
  acquireToken: (requestOverride?: PopupRequest | RedirectRequest | SilentRequest) => Promise<void>
  result: Ref<AuthenticationResult | null>
  error: Ref<AuthError | null>
  inProgress: Ref<boolean>
}

export function useMsalAuthentication (interactionType: InteractionType.Popup | InteractionType.Redirect, request: PopupRequest | RedirectRequest | SilentRequest): MsalAuthenticationResult {
  const { instance, inProgress } = useMsal()

  const localInProgress = ref<boolean>(false)
  const result = ref<AuthenticationResult | null>(null)
  const error = ref<AuthError | null>(null)

  const acquireToken = async (requestOverride?: PopupRequest | RedirectRequest | SilentRequest) => {
    if (!localInProgress.value) {
      localInProgress.value = true
      const tokenRequest = requestOverride ?? request

      if (inProgress.value === InteractionStatus.Startup || inProgress.value === InteractionStatus.HandleRedirect) {
        try {
          const response = await instance.handleRedirectPromise()
          if (response) {
            result.value = response
            error.value = null
            return
          }
        } catch (e) {
          result.value = null
          error.value = e as AuthError
          return
        };
      }

      try {
        const response = await instance.acquireTokenSilent(tokenRequest)
        result.value = response
        error.value = null
      } catch (e) {
        if (inProgress.value !== InteractionStatus.None) {
          return
        }

        if (interactionType === InteractionType.Popup) {
          instance.loginPopup(tokenRequest).then((response) => {
            result.value = response
            error.value = null
          }).catch((e) => {
            error.value = e
            result.value = null
          })
        } else if (interactionType === InteractionType.Redirect) {
          await instance.loginRedirect(tokenRequest).catch((e) => {
            error.value = e
            result.value = null
          })
        }
      };
      localInProgress.value = false
    }
  }

  const stopWatcher = watch(inProgress, () => {
    if (!result.value && !error.value) {
      acquireToken()
    } else {
      stopWatcher()
    }
  })

  acquireToken()

  return {
    acquireToken,
    result,
    error,
    inProgress: localInProgress
  }
}
